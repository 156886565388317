<template>
    <dialog
        ref="dialog"
        v-shortcuts
        @cancel="onDialogCancel"
        @close="onDialogClose"
    >
        <ButtonCircular
            v-if="showCloseButton"
            class="close-button small"
            icon="icon_close"
            @trigger="cancel"
        />

        <slot name="header">
            <h2 v-if="title" class="title">
                {{ title }}
            </h2>
        </slot>

        <slot v-if="isOpen || !removeMainSlotContentWhenInvisible" />

        <div v-if="$slots.buttons" ref="buttons" class="buttons">
            <slot name="buttons" />
        </div>
    </dialog>
</template>

<script lang="ts">
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import EventType from '@/Utility/EventType';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';

export default defineComponent({
    components: {
        ButtonCircular,
    },

    props: {
        /**
         * For example "MODAL_ABOUT" or "MODAL_PROGRESS".
         * @see {EventType}
         */
        eventType: {
            type: String,
            required: true,
        },

        /**
         * True if the user should be able to cancel this modal
         * by pressing ESC.
         */
        userClosable: {
            type: Boolean,
            default: true,
        },

        /**
         * True if this modal should show a little close button in
         * the upper right corner
         */
        showCloseButton: {
            type: Boolean,
            default: true,
        },

        /**
         * True if content of the main slot should be removed with a
         * v-if-directive when the modal is not visible to the user.
         * This can be used to avoid having to reset forms manually
         * on modal close but may lead to visual bugs when opening and
         * closing modals rapidly (programmatically).
         */
        removeMainSlotContentWhenInvisible: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String as PropType<string | null>,
            default: null,
        },
    },

    emits: {
        /**
         * Emitted when this dialog is shown via the XXX_SHOW event.
         * Any parameters emitted with this event will be passed.
         */
        'show': (..._: any[]) => true,

        /**
         * Emitted when this dialog closes. This may be caused by
         * the XXX_HIDE event, a direct call to hide(), submitting
         * a form or by canceling via the ESC key.
         */
        'hide': () => true,

        /**
         * Emitted when canceled via the close button or ESC key.
         */
        'cancel': () => true,
    },

    data() {
        return {
            isOpen: false,
            shortcuts: new Map([
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Publish.prevent', null],              // Prevent printing dialog
                ['Duplicate.prevent', null],            // Prevent browser behaviour
                ['Any', null]                           // Allow any other shortcut but stop propagation
            ])
        };
    },

    computed: {

        dialogElement(): HTMLDialogElement {
            return this.$refs.dialog as HTMLDialogElement;
        }
    },

    created() {
        // Make sure the given type's events exist:
        ['SHOW', 'HIDE'].forEach((suffix) => {
            const eventName = this.eventType + '_' + suffix;
            if (typeof EventType[eventName] !== 'string') {
                throw new TypeError(`Modal->created(): EventType ${eventName} doesn't exist.`);
            }
        });
    },

    mounted() {
        this.$globalEvents.on(EventType[this.eventType + '_SHOW'], this.show);
        this.$globalEvents.on(EventType[this.eventType + '_HIDE'], this.hide);
    },

    beforeUnmount() {
        this.$globalEvents.off(EventType[this.eventType + '_SHOW'], this.show);
        this.$globalEvents.off(EventType[this.eventType + '_HIDE'], this.hide);
    },

    methods: {

        show(...args: any[]) {
            this.isOpen = true;
            this.dialogElement.showModal();

            // Set focus on the primary button if it exists (always last button in our current design):
            ((this.$refs.buttons as HTMLDivElement)?.lastElementChild as HTMLElement)?.focus();

            this.$emit('show', ...args);
        },

        hide() {
            this.dialogElement.close();
        },

        /**
         * Closes the modal and will emit the cancel event.
         * Also, a global XX_CANCEL event will be emitted, if it exists.
         */
        cancel() {
            this.emitCancelEvents();
            this.dialogElement.close();
        },

        onDialogClose() {
            this.isOpen = false;
            this.$emit('hide');
        },

        onDialogCancel(e: Event) {
            // Prevent closing with [ESCAPE] key as HTML default action
            if (this.userClosable) {
                this.emitCancelEvents();
            } else {
                e.preventDefault();
            }
        },

        emitCancelEvents() {
            this.$emit('cancel');

            if (typeof EventType[this.eventType + '_CANCEL'] === 'string') {
                this.$globalEvents.emit(EventType[this.eventType + '_CANCEL']);
            }
        },
    },
});
</script>

<style lang="scss" scoped>

dialog {
    width: 400px;
    max-width: 80vw;
    min-width: 300px;
    border: none;
    padding: var(--modal-padding);
    background-color: var(--background-color-white);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    animation: modal-opened .15s;

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.3);
    }

    > .close-button {
        position: absolute;
        top: 16px;
        right: 16px;

        :deep(svg.icon) {
            flex-basis: 16px;
            width: 16px;
            height: 16px;
        }
    }

    > .buttons {
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;

        :deep(button) {
            white-space: nowrap;
        }
    }

    :deep(> hr) {
        margin: 0 -32px;
        border: none;
        border-top: 1px solid var(--color-anthracite20);
        opacity: 1;
    }

    :deep(> .checkbox) {
        padding: 10px 32px;
        margin: 0 -32px;
        background-color: var(--background-color-light);
        border-top: 1px solid var(--color-anthracite20);
        border-bottom: 1px solid var(--color-anthracite20);
    }

    :deep(h3) {
        margin-top: 16px;
    }

    ul, ol {
        margin-bottom: 20px;
        list-style-position: inside;
    }

    ol {
        li::marker {
            font-variant-numeric: lining-nums;
        }
    }
}

</style>
