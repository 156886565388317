<template>
    <div
        v-if="canReadFeatures"
        :data-loading="isLoading"
        :data-saving="isSaving"
        class="container"
    >

        <div class="row justify-content-left">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        {{ headline }}
                    </div>

                    <div class="card-body">
                        <table class="feature-list">

                            <tr v-if="showInstanceWarning">
                                <td colspan="3">
                                    <div class="instance-warning">
                                        <Icon name="icon_warning_triangle" />
                                        {{ trans('features.local.manage.instance_warning') }}
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>{{ trans('labels.feature') }}</th>
                                <th>{{ trans('labels.created_at') }}</th>
                                <th />
                            </tr>

                            <LocalFeatureListItem
                                v-for="feature in features"
                                :key="feature.uid"
                                :can-delete="canDeleteFeatures"
                                :feature="feature"
                                @click-remove="onRemoveClicked"
                            />

                            <tr v-if="features.length === 0">
                                <td class="cell-no-items" colspan="3">
                                    <NoItemsAvailable />
                                </td>
                            </tr>

                            <AddLocalFeatureForm
                                v-if="canCreateFeatures"
                                :feature-names="unusedFeatureNames"
                                @click-add="onAddClicked"
                            />
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <ModalProgress v-if="enableDialogs" />
        <ModalNotification v-if="enableDialogs" />

    </div>
</template>

<script lang="ts">
import EventType from '@/Utility/EventType';
import AuthorizationError from '@/Errors/AuthorizationError';
import LocalFeatureListItem from '@/Vue/Features/Local/LocalFeatureListItem.vue';
import {permission, sortArrayByProperty, trans} from '@/Utility/Helpers';
import AddLocalFeatureForm from '@/Vue/Features/Local/AddLocalFeatureForm.vue';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import {Permission} from '@/Models/User/Permission';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import {Feature} from '@/Models/Features/Feature';
import type {PropType} from 'vue';
import {defineComponent, inject} from 'vue';
import {localFeatureServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import Icon from '@/Vue/Common/Icon.vue';
import type LocalFeature from '@/Models/Features/Local/LocalFeature';

export default defineComponent({

    components: {
        Icon,
        ModalProgress,
        NoItemsAvailable,
        ModalNotification,
        AddLocalFeatureForm,
        LocalFeatureListItem,
    },

    props: {
        /**
         * Will display tenant specific features if set to a tenant uid.
         * Set it to null to manage instance features only.
         */
        tenantUid: {
            type: String as PropType<string | null>,
            default: null
        },
        /**
         * Names of all the features that are known to this instance.
         */
        featureNames: {
            type: Array as PropType<string[]>,
            default: Feature.all().map(feature => Feature.getName(feature))
        },
        /**
         * If false, the parent page has to provide error, loading and saving dialog.
         */
        enableDialogs: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            featureService: inject(localFeatureServiceKey)!,
            features: [] as LocalFeature[],
        };
    },

    computed: {

        canReadFeatures() {
            return permission(Permission.LocalFeaturesRead());
        },

        canCreateFeatures() {
            return permission(Permission.LocalFeaturesCreate());
        },

        canDeleteFeatures() {
            return permission(Permission.LocalFeaturesDelete());
        },

        isInstanceScoped() {
            return this.tenantUid === null;
        },

        showInstanceWarning() {
            return this.isInstanceScoped;
        },

        isLoading() {
            if (this.featureService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isSaving() {
            if (this.featureService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        headline() {
            return trans(
                this.isInstanceScoped ?
                    'features.local.manage.headline_instance' :
                    'features.local.manage.headline_tenant'
            );
        },

        unusedFeatureNames() {
            return this.featureNames
                .filter(name => this.features.every(existingFeature => existingFeature.name !== name));
        }
    },

    mounted() {
        if (this.canReadFeatures) {
            this.fetchFeatures();
        }
    },

    methods: {
        trans,

        fetchFeatures() {
            this.featureService
                .fetchLocalFeatures()
                .then(this.onFeaturesChanged)
                .catch(this.onErrorApi);
        },

        onFeaturesChanged() {
            this.features = sortArrayByProperty(
                this.featureService.features.filter(feature => feature.tenant_uid === this.tenantUid),
                'name'
            );
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root.forceLogout;
            }
            this.$root.showErrorDialog(error);
        },

        onRemoveClicked(feature: LocalFeature) {
            this.featureService
                .deleteLocalFeature(feature.uid)
                .then(this.onFeaturesChanged)
                .catch(this.onErrorApi);
        },

        onAddClicked(featureName: string) {
            this.featureService
                .createLocalFeature(featureName, this.tenantUid)
                .then(this.onFeaturesChanged)
                .catch(this.onErrorApi);
        },
    },
});
</script>

<style lang="scss" scoped>

.feature-list {
    width: 100%;

    .cell-no-items {
        padding: 16px;
    }

    .instance-warning {
        display: flex;
        margin-bottom: 16px;
        padding: 8px;
        border: 1px solid var(--color-secondary);
        border-radius: var(--card-border-radius-small);
        align-items: center;

        .icon {
            height: 24px;
            margin-right: 8px;
            color: var(--color-secondary);
        }
    }
}

</style>
